import React from 'react'
import Showdown from 'showdown'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { GatsbyImage } from 'gatsby-plugin-image'

class ColumnTextImageBelow extends React.Component {
  render() {
    const converter = new Showdown.Converter()

    return (
      <>
        <MDBRow>
          <MDBCol md="12 pb-4">
            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-3">{this.props.title}</h2>
            <div
              className="text-medium divlink"
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(this.props.description),
              }}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol lg="12 pt-5 text-center">
            <GatsbyImage
              image={this.props.image}
              alt={this.props.alttext}
              className="rounded"
            />
          </MDBCol>
        </MDBRow>
      </>
    )
  }
}

export default ColumnTextImageBelow
